import React from 'react';
import Button from '../Button/index';
import { MdChat } from 'react-icons/md';

import { Container } from './styles';
import { ICON_COLORS } from './colors.jsx';
import { useState } from 'react';

const ChatButton = ({ request, setChatRequest, setShowChat, kpiDiscussions = null, disabled = false, buttonText = "Discussion" }) => {
    const [userObject, setUserObject] = useState(JSON.parse(localStorage.getItem('@Thrive:user')));
    const IconDiscussionToAnswer = (kpiDiscussion) => {
        if (kpiDiscussion != null) {
            if (kpiDiscussion.messages != null && kpiDiscussion.messages != undefined) {
                if (kpiDiscussion.messages.length > 0) {
                    if (kpiDiscussion.messages[kpiDiscussion.messages.length - 1].createdBy != userObject.id) {
                        if (kpiDiscussion.lastDiscussionStatus == "Open")
                            return (<div className='icon-newdiscussion'></div>)
                    }
                }
            }
        }
    }
    var iconColor = ICON_COLORS.default;

    var buttonIconId = "discussion-button-icon-" + request.subSectionKPIID
        + "-" + request.column ?? 0
        + "-" + request.row ?? 0
        + "-" + request.tobaccoTypeID ?? 0
        + "-" + request.fuelTypeID ?? 0
        + "-" + request.curingBarnID ?? 0
        + "-" + request.countryID ?? 0;

    if (kpiDiscussions != null) {
        var kpiDiscussion = kpiDiscussions.find((d) => {
            return ((d.subSectionKPIID == request.subSectionKPIID
                && d.tobaccoTypeID == (request.tobaccoTypeID !== null ? request.tobaccoTypeID : null)
                && d.fuelTypeID == (request.fuelTypeId !== null ? request.fuelTypeId : null)
                && d.curingBarnID == (request.curingBarnID !== null ? request.curingBarnID : null)
                && d.countryID == (request.countryId !== null ? request.countryId : null)
                && d.row == (request.row !== null ? request.row : null)
                && d.column == (request.column !== null ? request.column : null)
                && d.lastDiscussionStatus != null) || (d.subSectionKPIID == request.subSectionKPIID && d.row == (request.row !== null ? request.row : null)
                    && d.column == (request.column !== null ? request.column : null)))
        });
        if (kpiDiscussion) {
            if (kpiDiscussion.lastDiscussionStatus == "Closed") {
                iconColor = ICON_COLORS.closed;
            }
            else {
                iconColor = ICON_COLORS.open;
            }
        }
    }

    return <Container
        onClick={() => {
            if (!disabled) {
                setChatRequest(request);
                setShowChat(true);
            }
        }}
        disabled={disabled}>
        <Button disabled={disabled} style={{ position: 'relative' }}>
            {buttonText}
            <MdChat
                color={iconColor}
                id={buttonIconId}
                className="icon-chat"
                size={30}
            />
            {IconDiscussionToAnswer(kpiDiscussion)}

        </Button>
    </Container>
}

export default ChatButton;
