import TableCrud from '../../components/TableCrud';
import React, { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/auth';
import { useParams } from 'react-router-dom';
import TableLoading from '../../components/TableLoading/index';
import { error_message, success_message } from '../../components/Toast/index.jsx';
import Api, { exceptionNotificationAPI } from '../../services/api';
import { Container, Table } from './styles';
import Select from 'react-select';

const RejectDashboard = () => {

    const { user, userRoles } = useAuth();
    const { companyID, reportYearID, moduleID } = useParams();

    const [userObject, setUserObject] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadingView, setLoadingView] = useState(false);

    const [blockModules, setBlockModules] = useState(true);

    //Operation Select Variables
    const [selectedCompanies, setSelectedCompanies] = useState([]);
    const [selectedModules, setSelectedModules] = useState([]);
    const [companies, setCompanies] = useState('');
    const [modules, setModules] = useState('');

    //Report Year Select Variables
    const [reportYearList, setReportYearList] = useState([]);
    const [ReportYear, setReportYear] = useState('');
    const [toReportYear, setToReportYear] = useState('');

    useEffect(() => {
        var userAux = typeof (user) == "string" ? JSON.parse(user) : user;
        setUserObject(userAux);
        loadCropYears(userAux);
        loadCompanies();
        setLoading(false);
    }, []);

    const loadCropYears = () => {
        Api.get(`thriveValue/getAllCropsYears`).then((result) => {
            setLoading(true);
            if (result && result.data) {
                var reportYearOptions = result.data.response?.map((option) => {
                    var data = {
                        value: option.reportYearID,
                        label: option.year
                    }
                    return data;
                });
                setReportYearList(reportYearOptions);
            }
            setLoading(false);
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadCompanies = () => {
        Api.get(`company/getAll`).then((result) => {
            if (result && result.data) {
                let response = result.data.response;
                if (response.length > 0) {
                    console.log(`minhas respostas`, response)
                    var companyOptions = response?.map((option) => {
                        var data = {
                            value: option.companyID,
                            label: option.name
                        }
                        return data;
                    });
                    setCompanies(companyOptions);
                } else {
                    response.push({
                        value: "",
                        label: `No items found, please select another Report Year`,
                    })
                    setCompanies(response);
                }

                setLoading(false);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const loadPromptActionsModules = (reportYearID) => {
        Api.get(`module/GetPromptActionsModules?reportYearID=${reportYearID}`).then((result) => {
            if (result && result.data) {
                let response = result.data.response;
                if (response.length > 0) {
                    var moduleOptions = response?.map((option) => {
                        var data = {
                            value: option.moduleID,
                            label: option.description
                        }
                        return data;
                    });
                    setModules(moduleOptions);
                } else {
                    response.push({
                        value: "",
                        label: `No items found, please select another Report Year`,
                    })
                    setModules(response);
                }
                setLoading(false);
            }
        }).catch((error) => {
            exceptionNotificationAPI(error);
        });
    }

    const onChangeReportYearID = (reportYear) => {
        setReportYear(reportYear);
        loadPromptActionsModules(reportYear.value);
    }

    const onChangeCompanies = (companies) => {
        console.log(companies)
        setSelectedCompanies(companies)
    }

    const onChangeModules = (modules) => {
        console.log(modules)
        setSelectedModules(modules);
    }

    const rejectModules = () => {
        setLoadingView(true);

        if (selectedCompanies && selectedModules && ReportYear) {
            var data = {
                ReportYears: [ReportYear.value],
                Companies: selectedCompanies ? selectedCompanies.map(x => x.value) : null,
                Tobaccos: null,
                Modules: selectedModules ? selectedModules.map(x => x.value) : null
            };

            Api.post(`ThriveValue/rejectModules`, data).then((r) => {
                if (r.data.response) {
                    success_message("The modules were rejected!")
                } else {
                    error_message("The modules weren't rejected!")
                }

                setLoading(false)
            }).catch((error) => {
                exceptionNotificationAPI(error);
            });
            console.log(data)
            setLoadingView(false);
        } else {
            error_message("Error ocurred during rejection");
            setLoadingView(false);
        }
    }

    return (
        <Container className="container">
            <div className="row mt-2">

                <div className="col-md-10" style={{
                    fontSize: '24px',
                    fontWeight: 600,
                    display: 'flex',
                    justifyContent: 'center',
                    margin: 'auto',
                    marginBottom: '1rem',
                    marginTop: '1rem'
                }}>Rejection Dashboard</div>

            </div>
            <div className="row mt-2">
                <label>
                    Report Year
                </label>
                <Select
                    title={'reportYear'}
                    options={reportYearList}
                    value={ReportYear}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    //onChange={(a) => setMultipleValues(a)}
                    className="col-md-12"
                    onChange={onChangeReportYearID}
                />
            </div>
            <div className="row mt-2">
                <label>
                    Suppliers
                </label>
                <Select
                    title={'companies'}
                    options={companies}
                    value={selectedCompanies}
                    isMulti
                    closeMenuOnSelect={false}
                    //onChange={(a) => setMultipleValues(a)}
                    className="col-md-12"
                    onChange={onChangeCompanies}
                />
            </div>
            <div className="row mt-2">
                <label>
                    Modules
                </label>
                <Select
                    title={'modules'}
                    options={modules}
                    value={selectedModules}
                    isMulti
                    closeMenuOnSelect={false}
                    //onChange={(a) => setMultipleValues(a)}
                    className="col-md-12"
                    onChange={onChangeModules}
                />
            </div>
            {
                loadingView ?
                    <div className="mt-2" style={{ padding: 0 }}>
                        <TableLoading />
                    </div> : <button className="col-md-12 apply-btn mt-4" onClick={() => rejectModules()}>Reject</button>
            }

        </Container>
    );
}

export default RejectDashboard;
